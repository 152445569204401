import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { UUID } from "crypto";
import { useCheckoutStore } from "./useCheckoutStore";

interface CartState {
  id: UUID | null;
  version: number;
}

interface CartActions {
  reset: () => void;
}

export type CartStore = CartState & CartActions;

const defaultState: CartState = {
  id: null,
  version: 1,
};

export const useCartStore = create<CartStore>()(
  devtools(
    persist(
      (set, get) => ({
        ...defaultState,
        reset: () => set(defaultState),
      }),
      {
        name: "cart",
      },
    ),
  ),
);
