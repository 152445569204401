import { UUID } from "crypto";

import * as Sentry from "@sentry/nextjs";
import { assign, fromPromise, setup } from "xstate";

import {
  addCartItems,
  createCart,
  getOrder,
  removeCartItems,
  submitCart,
  updateCartItems,
  updateCartNotes,
  updateCoupon,
  updateCustomerIdentity,
  updateCustomFields,
  validateCoupon,
} from "@repo/libs/api/cart";
import { PaymentMethod } from "@repo/libs/types/app";
import {
  Cart,
  CartEvents,
  CartItemInput,
  CartItemUpdateInput,
  CartMachineContext,
} from "@repo/libs/types/cart";
import { CustomFieldInput } from "@repo/libs/types/customFields";
import { CartCustomerIdentityInput } from "@repo/libs/types/storefront";
import { storageAvailable } from "@repo/libs/utils";

import { Toast } from "@repo/ui/components/use-toast";

import { useCartStore } from "@website-toolkit/stores/cart/useCartStore";
import { useCheckoutStore } from "@website-toolkit/stores/cart/useCheckoutStore";
import { useCustomerStore } from "@website-toolkit/stores/useCustomerStore";

const initialState: CartMachineContext = {
  cartId: undefined,
  cart: undefined,
  errorRetries: 0,
  isSheetOpen: false,
};

const IdleEvents = {
  FetchCart: {
    target: "Fetching",
  },
  AddItems: {
    target: "AddingItems",
  },
  RemoveItems: {
    target: "RemovingItems",
  },
  UpdateItems: {
    target: "UpdatingItems",
  },
  UpdateNote: {
    target: "UpdatingNote",
  },
  UpdateCustomerIdentity: {
    target: "UpdatingCustomerIdentity",
  },
  UpdateCustomFields: {
    target: "UpdatingCustomFields",
  },
  UpdateCoupon: {
    target: "UpdatingCoupon",
  },
  SubmitCart: {
    target: "SubmittingCart",
  },
};
export const machine = (
  toaster: (message: Toast) => void,
  adminMode: boolean,
) =>
  setup({
    types: {
      context: initialState,
      events: {} as CartEvents,
    },
    actors: {
      localStorageFetchId: fromPromise(async () => {
        if (!storageAvailable("localStorage")) return undefined;

        const currentVer = useCartStore.getState().version;
        const latestVer = 3;
        if (currentVer < latestVer) {
          useCartStore.setState(() => ({ id: null, version: latestVer }));
        }
        const id = useCartStore.getState().id;
        return (useCartStore.getState().id ?? null) as UUID | null;
      }),
      fetch: fromPromise(
        async ({ input }: { input: { cartId: UUID } }): Promise<Cart> => {
          if (adminMode) return { id: input.cartId };
          return await getOrder(input?.cartId, true);
        },
      ),
      create: fromPromise(async () => {
        if (adminMode) return { id: crypto.randomUUID() };
        return await createCart({});
      }),
      addItems: fromPromise(
        async ({
          input,
        }: {
          input: {
            cartId: UUID;
            items: CartItemInput[];
            context: CartMachineContext;
          };
        }) => {
          if (input.items.length === 0) return input.context.cart;
          return (await addCartItems(input.cartId, input.items)) as Cart;
        },
      ),
      removeItems: fromPromise(
        async ({ input }: { input: { cartId: UUID; itemIds: UUID[] } }) => {
          return (await removeCartItems(input.cartId, input.itemIds)) as Cart;
        },
      ),
      updateItems: fromPromise(
        async ({
          input,
        }: {
          input: { cartId: UUID; items: CartItemUpdateInput[] };
        }) => {
          return (await updateCartItems(input.cartId, input.items)) as Cart;
        },
      ),
      UpdateNote: fromPromise(
        async ({ input }: { input: { cartId: UUID; note: string } }) => {
          return (await updateCartNotes(input.cartId, input.note)) as Cart;
        },
      ),
      UpdateCustomerIdentity: fromPromise(
        async ({
          input,
        }: {
          input: { cartId: UUID; customerIdentity: CartCustomerIdentityInput };
        }) => {
          const cart = (await updateCustomerIdentity(
            input.cartId,
            input.customerIdentity,
          )) as Cart;
          if (cart.couponCode) {
            await validateCoupon({
              couponCode: cart.couponCode as any,
              cartItems: cart.cartItems as any[],
              customerId: cart.customerId as any,
            });
          }
          return cart;
        },
      ),
      UpdateCustomFields: fromPromise(
        async ({
          input,
        }: {
          input: {
            cartId: UUID;
            cartItemId: UUID;
            customFields: CustomFieldInput[];
          };
        }) => {
          return (await updateCustomFields(
            input.cartId,
            input.cartItemId,
            input.customFields,
          )) as Cart;
        },
      ),
      UpdateCoupon: fromPromise(
        async ({ input }: { input: { cartId: UUID; coupon?: string } }) => {
          return (await updateCoupon(input.cartId, input.coupon)) as Cart;
        },
      ),
      SubmitCart: fromPromise(
        async ({
          input,
        }: {
          input: { cartId: UUID; paymentMethod: PaymentMethod };
        }) => {
          return (await submitCart(input.cartId, input.paymentMethod)) as Cart;
        },
      ),
    },
    guards: {
      IsSheetOpen: ({ context }) => context.isSheetOpen,
      isSheetClosed: ({ context }) => !context.isSheetOpen,
      localStorageCartIdAvailable: ({ context }) =>
        context.cartId !== null && context.cartId !== undefined,
      localStorageCartIdNotAvailable: ({ context }) => context.cartId === null,
      maxRetriesExceeded: ({ context }) => context.errorRetries >= 5,
      "404": function({ context, event }) {
        const innerError = (event as any).error?.error as any;
        if (innerError?.message) {
          toaster({
            title: innerError.message,
            description: innerError?.details,
            variant: "destructive",
          });
        }
        return (event as any).error.httpStatus === 404;
      },
    },
  }).createMachine({
    context: initialState,
    id: "CartMachine",
    initial: "Uninitialized",
    states: {
      Uninitialized: {
        tags: ["IsIdleState"],
        always: [
          {
            target: "Fetching",
            guard: "localStorageCartIdAvailable",
          },
          {
            target: "Creating",
            guard: "localStorageCartIdNotAvailable",
          },
        ],
        invoke: {
          input: {},
          src: "localStorageFetchId",
          onDone: {
            actions: assign({ cartId: ({ event }) => event.output }),
          },
        },
      },
      Fetching: {
        invoke: {
          input: ({ context: { cartId } }) => ({ cartId: cartId! }),
          src: "fetch",
          onDone: {
            target: "Idle",
            actions: assign({ cart: ({ event }) => event.output }),
          },
          onError: {
            target: "Error",
          },
        },
      },
      Creating: {
        invoke: {
          src: "create",
          onDone: {
            target: "Idle",
            actions: [
              assign({
                cart: ({ event }) => event.output,
                cartId: ({ event }) => event.output.id,
              }),
              ({ context }) => {
                useCartStore.setState({ id: context.cart!.id as UUID });
              },
            ],
          },
          onError: {
            target: "Error",
          },
        },
      },
      Idle: {
        entry: assign({ errorRetries: 0 }),
        tags: ["IsIdleState"],
        always: [
          {
            target: "Completed",
            guard: ({ context }) => context.cart?.status === 3,
          },
          {
            target: "ReviewingOrder",
            guard: "IsSheetOpen",
          },
        ],
        on: {
          ...IdleEvents,
          CompleteCart: {
            target: "Uninitialized",
            actions: [
              assign(initialState),
              () => {
                useCartStore.setState({ id: null });
              },
            ],
          },
          OpenSheet: { actions: assign({ isSheetOpen: true }) },
        },
      },
      AddingItems: {
        invoke: {
          src: "addItems",
          input: ({ context, event }) => {
            if (event.type !== "AddItems")
              throw new Error("Invalid event type");
            return {
              cartId: context.cartId!,
              items: event.items,
              context: context,
            };
          },
          onDone: {
            actions: [
              assign({ cart: ({ event }) => event.output, isSheetOpen: true }),
            ],
            target: "Idle",
          },
          onError: {
            target: "Error",
          },
        },
      },
      RemovingItems: {
        invoke: {
          src: "removeItems",
          input: ({ context: { cartId }, event }) => {
            if (event.type !== "RemoveItems")
              throw new Error("Invalid event type");
            return { cartId: cartId!, itemIds: event.itemIds };
          },
          onDone: {
            actions: [assign({ cart: ({ event }) => event.output })],
            target: "Idle",
          },
          onError: {
            target: "Error",
          },
        },
      },
      UpdatingItems: {
        invoke: {
          src: "updateItems",
          input: ({ context: { cartId }, event }) => {
            if (event.type !== "UpdateItems")
              throw new Error("Invalid event type");
            return { cartId: cartId!, items: event.items };
          },
          onDone: {
            actions: [assign({ cart: ({ event }) => event.output })],
            target: "Idle",
          },
          onError: {
            target: "Error",
          },
        },
      },
      UpdatingNote: {
        invoke: {
          src: "UpdateNote",
          input: ({ context: { cartId }, event }) => {
            if (event.type !== "UpdateNote")
              throw new Error("Invalid event type");
            return { cartId: cartId!, note: event.note };
          },
          onDone: {
            actions: [assign({ cart: ({ event }) => event.output })],
            target: "Idle",
          },
          onError: {
            target: "Error",
          },
        },
      },
      UpdatingCustomerIdentity: {
        invoke: {
          src: "UpdateCustomerIdentity",
          input: ({ context: { cartId }, event }) => {
            if (event.type !== "UpdateCustomerIdentity")
              throw new Error("Invalid event type");
            return {
              cartId: cartId!,
              customerIdentity: event.customerIdentity,
            };
          },
          onDone: {
            actions: [
              assign({ cart: ({ event }) => event.output }),
              () => useCheckoutStore.getState().nextStep(),
            ],
            target: "Idle",
          },
          onError: {
            target: "Error",
          },
        },
      },
      UpdatingCustomFields: {
        invoke: {
          src: "UpdateCustomFields",
          input: ({ context: { cartId }, event }) => {
            if (event.type !== "UpdateCustomFields")
              throw new Error("Invalid event type");
            return {
              cartId: cartId!,
              cartItemId: event.cartItemId,
              customFields: event.customFields,
            };
          },
          onDone: {
            actions: [assign({ cart: ({ event }) => event.output })],
            target: "Idle",
          },
          onError: {
            target: "Error",
          },
        },
      },
      UpdatingCoupon: {
        invoke: {
          src: "UpdateCoupon",
          input: ({ context: { cartId }, event }) => {
            if (event.type !== "UpdateCoupon")
              throw new Error("Invalid event type");
            return { cartId: cartId!, coupon: event.coupon };
          },
          onDone: {
            actions: [assign({ cart: ({ event }) => event.output })],
            target: "Idle",
          },
          onError: {
            target: "Error",
          },
        },
      },
      ReviewingOrder: {
        tags: ["IsIdleState"],
        on: {
          ...IdleEvents,
          CompleteCart: {
            target: "Uninitialized",
            actions: [
              assign(initialState),
              () => useCartStore.setState({ id: null }),
            ],
          },
          CloseSheet: {
            // target: "Idle",
            actions: [
              assign({ isSheetOpen: false }),
              () => useCheckoutStore.getState().reset(),
            ],
          },
        },
        always: [
          {
            target: "Completed",
            guard: ({ context }) => context.cart?.status === 3,
          },
          {
            target: "Idle",
            guard: {
              type: "isSheetClosed",
            },
          },
        ],
      },
      SubmittingCart: {
        invoke: {
          src: "SubmitCart",
          input: ({ context: { cartId }, event }) => {
            if (event.type !== "SubmitCart")
              throw new Error("Invalid event type");
            return { cartId: cartId!, paymentMethod: event.paymentMethod! };
          },
          onDone: {
            target: "Uninitialized",
          },
          onError: {
            target: "Error",
          },
        },
      },
      Error: {
        entry: [
          assign({
            errorRetries: ({ context }) => context.errorRetries + 1,
          }),
          ({ event }) => {
            const error = (event as any).error;
            const httpStatus = error?.httpStatus ?? 500;
            const isUserFriendlyError =
              httpStatus >= 400 && httpStatus < 500 && httpStatus !== 404;

            if (
              httpStatus === 404 &&
              (event.type.includes("Fetching") || event.type === "AddItems")
            )
              return;

            if (isUserFriendlyError) {
              toaster({
                title: error?.error.message || "An error occurred",
                description: error?.error.details,
                variant: "destructive",
              });
            } else {
              Sentry.captureException(error);
              toaster({
                title: "An error occurred", // TODO: translate zis and log to sentry
                variant: "destructive",
              });
            }
          },
        ],
        always: [
          {
            target: "Uninitialized",
            actions: [
              assign(initialState),
              () => {
                useCartStore.setState({ id: null });
              },
            ],
            guard: ({ context, event }) => {
              const error = (event as any).error;
              return context.errorRetries >= 5 || error?.httpStatus === 404;
            },
          },
          {
            target: "Idle",
          },
        ],
      },
      Completed: {
        on: {
          CompleteCart: {
            target: "Uninitialized",
            actions: [
              assign(initialState),
              () => {
                useCartStore.setState({ id: null });
                useCustomerStore.getState().reset();
              },
            ],
          },
        },
      },
    },
  });
