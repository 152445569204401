import { UUID } from "crypto";

import { CartCreationError } from "@libs/errors/cartErrors";
import { request } from "@libs/request";
import { PaymentMethod } from "@libs/types/app";
import {
  Cart,
  CartItemInput,
  CartItemUpdateInput,
  Order,
} from "@libs/types/cart";
import { Coupon, CouponCart } from "@libs/types/coupon";
import { CustomFieldInput } from "@libs/types/customFields";
import { CartCustomerIdentityInput } from "@libs/types/storefront";
import { mappedPaymentMethod } from "@libs/utils";

import { CartInput } from "../types/cart/index";

export async function createCart(cart: CartInput) {
  const response = await request("POST", "api/app/cart/new-cart", cart);
  return response.data;
}

export const getCart = async (id: UUID) => {
  const response = await request("GET", `api/app/cart/${id}`);
  return response.data;
};

export const setCart = async (cart: Cart) => {
  const response = await request("POST", "api/app/cart/set", { cart });
  return response.data;
};

export const addCartItems = async (
  cartId: UUID,
  cartItems: CartItemInput[],
) => {
  const response = await request(
    "POST",
    `api/app/cart/cart-items/${cartId}`,
    stringify(cartItems),
  );
  return response.data;
};

export const removeCartItems = async (cartId: UUID, cartItemIds: UUID[]) => {
  const deleteQuery = cartItemIds.map((id) => `itemsIds=${id}`).join("&");
  const response = await request(
    "DELETE",
    `api/app/cart/cart-items/${cartId}?${deleteQuery}`,
  );
  return response.data;
};

export const updateCartItems = async (
  cartId: UUID,
  cartItems: CartItemUpdateInput[],
) => {
  const response = await request(
    "PUT",
    `api/app/cart/items/${cartId}`,
    JSON.stringify(cartItems),
  );
  return response.data;
};

export const updateCoupon = async (cartId: UUID, couponCode?: string) => {
  const response = await request(
    "PUT",
    `api/app/cart/coupon/${cartId}?couponCode=${couponCode}`,
  );
  return response.data;
};

export const validatePackage = async (cartId: UUID) => {
  const response = await request("PUT", `api/app/cart/package/${cartId}`);
  return response.data;
};

export const updateCart = async (cartId: UUID, cart: Cart) => {
  const response = await request("PUT", `api/app/cart/cart-items/${cartId}`, {
    cart,
  });
  return response.data;
};

export const updateCustomFields = async (
  cartId: UUID,
  cartItemId: UUID,
  customFields: CustomFieldInput[],
) => {
  const response = await request(
    "POST",
    `api/app/cart/custom-fields?cartId=${cartId}&cartItemId=${cartItemId}`,
    JSON.stringify(customFields),
  );
  return response.data;
};

export const updateCartNotes = async (cartId: UUID, notes: string) => {
  const response = await request(
    "PUT",
    `api/app/cart/notes/${cartId}?notes=${notes}`,
  );
  return response.data;
};

export const updateCustomerIdentity = async (
  cartId: UUID,
  customerIdentity: CartCustomerIdentityInput,
) => {
  const response = await request(
    "PUT",
    `api/app/cart/customer/${cartId}?name=${customerIdentity.name}&mobileNumber=${customerIdentity.phone}&otpToken=${customerIdentity.otpToken}`,
    JSON.stringify(customerIdentity.customFields),
  );
  return response.data;
};

export async function validateCoupon(cartCoupon: Partial<CouponCart>) {
  const response = await request(
    "POST",
    "api/app/coupon/check-coupon",
    cartCoupon,
  );
  return response.data as Coupon;
}

export async function submitCart(payload: UUID, paymentMethod: PaymentMethod) {
  let paymentMethodIndex = mappedPaymentMethod(paymentMethod);

  const response = await request(
    "POST",
    `api/app/cart/${payload}/process?paymentMethod=${paymentMethodIndex}`,
  );
  return response.data;
}

export async function getOrder(
  orderId: UUID,
  allowNonPending: boolean = false,
) {
  const response = await request(
    "GET",
    `api/app/cart/${orderId}?allowNonPending=${allowNonPending}`,
  );
  return response.data as Cart;
}

export async function validateCart(cartId?: UUID) {
  const response = await request(
    "POST",
    `api/app/cart/validate-cart/${cartId}`,
  );
  return response.data;
}

export async function setBankTransferInfo(
  cartId: UUID,
  transferorName: string,
  formData: FormData,
) {
  const response = await request(
    "POST",
    `api/app/cart/set-bank-transfer-info/${cartId}?transferorName=${transferorName}`,
    formData,
  );
  return response.data;
}

function stringify(obj: any): any {
  if (typeof obj === "boolean") {
    return obj ? "true" : "false";
  }

  if (Array.isArray(obj)) {
    return obj.map(stringify);
  }

  if (typeof obj === "object" && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]: any) => [key, stringify(value)]),
    );
  }

  return obj;
}
