import { create } from "zustand";

import { CheckoutStep } from "@libs/types/checkout";

import { useCustomerStore } from "../useCustomerStore";

type CheckoutEvent =
  | { type: "NEXT" }
  | { type: "BACK" }
  | { type: "GOTO"; step: CheckoutStep }
  | { type: "RESET" };

interface State {
  stack: CheckoutStep[];
  step: CheckoutStep;
}

interface Actions {
  send: (event: CheckoutEvent) => void;
  nextStep: () => void;
  prevStep: () => void;
  setStep: (step: CheckoutStep) => void;
  reset: () => void;
}

interface StateConfig {
  next?: CheckoutStep;
  prev?: CheckoutStep;
  validTransitions: CheckoutStep[];
  guards?: {
    next?: () => boolean;
    prev?: () => boolean;
  };
}

type MachineConfig = {
  [K in CheckoutStep]: StateConfig;
};

const createCheckoutMachine = () => {
  const { otpEnabled, otpVerified } = useCustomerStore.getState();

  const baseConfig: MachineConfig = {
    [CheckoutStep.Summary]: {
      next:
        otpEnabled && !otpVerified
          ? CheckoutStep.SendOTP
          : CheckoutStep.CustomerInfo,
      validTransitions:
        otpEnabled && !otpVerified
          ? [CheckoutStep.SendOTP, CheckoutStep.VerifyOTP]
          : [CheckoutStep.CustomerInfo],
    },
    [CheckoutStep.CustomerInfo]: {
      next: CheckoutStep.SelectPaymentMethods,
      prev: CheckoutStep.Summary,
      validTransitions: [
        CheckoutStep.Summary,
        CheckoutStep.SelectPaymentMethods,
      ],
    },
    [CheckoutStep.SelectPaymentMethods]: {
      next: CheckoutStep.Payment,
      prev: CheckoutStep.CustomerInfo,
      validTransitions: [
        CheckoutStep.Summary,
        CheckoutStep.CustomerInfo,
        CheckoutStep.Payment,
      ],
    },
    [CheckoutStep.Payment]: {
      prev: CheckoutStep.SelectPaymentMethods,
      validTransitions: [CheckoutStep.SelectPaymentMethods],
    },
    [CheckoutStep.SendOTP]: {
      next: CheckoutStep.VerifyOTP,
      prev: CheckoutStep.Summary,
      validTransitions: [CheckoutStep.Summary, CheckoutStep.VerifyOTP],
    },
    [CheckoutStep.VerifyOTP]: {
      next: CheckoutStep.CustomerInfo,
      prev: CheckoutStep.SendOTP,
      validTransitions: [CheckoutStep.SendOTP, CheckoutStep.CustomerInfo],
      guards: {
        next: () => otpVerified,
      },
    },
  };

  return baseConfig;
};

const transition = (
  currentStep: CheckoutStep,
  event: CheckoutEvent,
  machine: MachineConfig,
): CheckoutStep => {
  const currentState = machine[currentStep];

  switch (event.type) {
    case "NEXT": {
      if (!currentState.next) return currentStep;
      if (currentState.guards?.next && !currentState.guards.next()) {
        return currentStep;
      }
      return currentState.next;
    }

    case "BACK": {
      if (!currentState.prev) return currentStep;
      if (currentState.guards?.prev && !currentState.guards.prev()) {
        return currentStep;
      }
      return currentState.prev;
    }

    case "GOTO": {
      // if (!currentState.validTransitions.includes(event.step)) {
      //   return currentStep;
      // }
      return event.step;
    }

    case "RESET":
      return CheckoutStep.Summary;

    default:
      return currentStep;
  }
};

const defaultState: State = {
  stack: [],
  step: CheckoutStep.Summary,
};

export const useCheckoutStore = create<State & Actions>()((set, get) => ({
  ...defaultState,

  send: (event: CheckoutEvent) => {
    const machine = createCheckoutMachine();
    const currentStep = get().step;
    const nextStep = transition(currentStep, event, machine);
    set({ step: nextStep, stack: [...get().stack, currentStep] });
  },

  setStep: (step: CheckoutStep) => {
    get().send({ type: "GOTO", step });
  },
  nextStep: () => get().send({ type: "NEXT" }),
  prevStep: () => get().send({ type: "BACK" }),
  reset: () => get().send({ type: "RESET" }),
}));
