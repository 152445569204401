import * as Sentry from "@sentry/nextjs";
import axios, { AxiosError, AxiosResponse, Method } from "axios";

// import { getTenant } from "./utils";
import { useTenantStore } from "./stores/useTenantStore";

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
});

// const lang = window.location.pathname;

// if (lang.includes("ar")) {
axiosInstance.defaults.headers.common["Accept-Language"] = "ar";
// }

export const request = async (
  method: Method,
  url: string,
  body?: unknown, // Use 'unknown' for safer typing
  headers?: Record<string, string>, // Existing headers
  contentType: ContentType = ContentType.JSON, // Default content type
  accept: ContentType = ContentType.JSON, // Default accept type
): Promise<{ data: any; status: number }> => {
  try {
    // Set the 'Content-Type' header to the specified content type or default to JSON
    const tenant = useTenantStore.getState().tenant;
    console.log("tenant from request", tenant);
    if (!tenant) {
      throw new Error("Tenant not found in local storage");
    }

    const effectiveHeaders = {
      "Content-Type": contentType,
      Accept: accept,
      __tenant: tenant,
      ...headers,
    };

    if (body instanceof FormData) {
      //@ts-ignore
      delete effectiveHeaders["Content-Type"];
    }

    const response: AxiosResponse = await axiosInstance({
      method,
      url,
      data: body,
      headers: effectiveHeaders,
    });

    // Request Succeeded!
    return { data: response.data, status: response.status };
  } catch (error) {
    const axiosError = error as AxiosError;
    const defaultMessage = "لقد حدث خطأ، يرجى المحاولة مرة أخرى";
    if (axiosError.response) {
      // Request made and server responded
      const { data, status } = axiosError.response;
      // if data is object spread it, otherwise return it as is
      if (typeof data === "object") throw { ...data, httpStatus: status };
      throw { data, httpStatus: status };
    } else {
      // Something happened in setting up the request that triggered an Error
      Sentry.captureException(axiosError);
      throw axiosError;
    }
  }
};

// content-types.ts

export enum ContentType {
  JSON = "application/json",
  XML = "application/xml",
  HTML = "text/html",
  Text = "text/plain",
  JavaScript = "application/javascript",
  CSS = "text/css",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  PDF = "application/pdf",
}

// request.ts
// import { getHost, getTenantName } from "./utils";
// import { ContentType } from "./content-types";
//
// export const request = async (
//   method: string,
//   url: string,
//   body?: unknown, // Use 'unknown' for safer typing
//   headers?: Record<string, string>, // Existing headers
//   contentType: ContentType = ContentType.JSON, // Default content type
//   accept: ContentType = ContentType.JSON, // Default accept type
// ): Promise<{ data: any; status: number }> => {
//   try {
//     // Set the 'Content-Type' header to the specified content type or default to JSON
//     const tenant = getTenantName(getHost());
//     const effectiveHeaders = {
//       "Content-Type": contentType,
//       Accept: accept,
//       __tenant: tenant,
//       ...headers,
//     };
//
//     const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}${url}`, {
//       method,
//       headers: effectiveHeaders,
//       body: body ? JSON.stringify(body) : undefined,
//     });
//
//     if (!response.ok) {
//       const errorData = await response.json();
//       throw { data: errorData, status: response.status };
//     }
//
//     const data = await response.json();
//     return { data, status: response.status };
//   } catch (error) {
//     const defaultMessage = "Unknown error, could you try again?";
//     if (error.status) {
//       // Error object has 'status' property, likely from server response
//       throw error.data;
//     } else {
//       // Something happened in setting up the request that triggered an Error
//       throw new Error(defaultMessage);
//     }
//   }
// };
