"use client";

import { UUID } from "crypto";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";

import { useRouter } from "next/navigation";

import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { PartialDeep } from "type-fest";

import { useCurrentLocale, useI18n } from "@repo/libs/providers/locales/client";
import { useTenantRouter } from "@repo/libs/providers/tenant/client";
import { PaymentMethod } from "@repo/libs/types/app";
import { CartItemInput, CartItemUpdateInput } from "@libs/types/cart";
import { CartBase, CartWithActions } from "@libs/types/cart/index.js";
import { CustomFieldInput } from "@libs/types/customFields";
import { CartCustomerIdentityInput } from "@libs/types/storefront";

import { useToast } from "@ui/use-toast";

import { useOtherMachine } from "@website-toolkit/hooks/useOtherMachine";

/**
 * Used to Sync backend and frontend cart state
 **/

export const CartContext = createContext<any | null>(null);

export function useCart(): CartWithActions {
  const context = useContext<CartWithActions>(CartContext);

  if (!context) {
    throw new Error("Expected a Cart Context, but no Cart Context was found");
  }
  return { ...context };
}

type CartProviderProps = {
  children: React.ReactNode;
  numCartItems?: number;
  adminMode: boolean;
  onCreate?: () => void;
  onSubmit?: () => void;
  onItemAdd?: () => void;
  onItemRemove?: () => void;
  onItemUpdate?: () => void;
  onNoteUpdate?: () => void;
  onCustomerIdentityUpdate?: () => void;
  onCustomFieldsUpdate?: () => void;
  onCouponCodeUpdate?: () => void;
  onCreateComplete?: () => void;
  onSubmitComplete?: () => void;
  onItemAddComplete?: () => void;
  onItemRemoveComplete?: () => void;
  onItemUpdateComplete?: () => void;
  onNoteUpdateComplete?: () => void;
  onCustomerIdentityUpdateComplete?: () => void;
  onCustomFieldsUpdateComplete?: () => void;
  onCouponCodeUpdateComplete?: () => void;
  data?: PartialDeep<CartBase, { recurseIntoArrays: true }>;
};

export function CartProvider({
  children,
  adminMode,
  onCreate,
  onSubmit,
  onItemAdd,
  onItemRemove,
  onItemUpdate,
  onNoteUpdate,
  onCustomerIdentityUpdate,
  onCustomFieldsUpdate,
  onCouponCodeUpdate,
  onCreateComplete,
  onSubmitComplete,
  onItemAddComplete,
  onItemRemoveComplete,
  onItemUpdateComplete,
  onNoteUpdateComplete,
  onCustomerIdentityUpdateComplete,
  onCustomFieldsUpdateComplete,
  onCouponCodeUpdateComplete,
}: CartProviderProps): JSX.Element {
  const { toast } = useToast();
  const t = useI18n();
  const [cartState, cartSend] = useOtherMachine({ toast, adminMode });
  useEffect(() => {
    // console.log("cart state machine", cartState);
  }, [cartState]);
  const router = useRouter();
  var tenantRouter = useTenantRouter();
  // // Delays the cart state in the context if the page is hydrating
  // // preventing suspense boundary errors.
  const cartDisplayState = useDelayedStateUntilHydration(cartState);
  const hasNavigated = useRef(false);

  useEffect(() => {
    if (cartDisplayState.matches("Completed") && !hasNavigated.current) {
      hasNavigated.current = true;
      router.push(
        tenantRouter("/checkout/done/" + cartDisplayState.context.cartId),
      );
    }
  }, [cartDisplayState]);

  const cartContextValue = useMemo<CartWithActions>(() => {
    return {
      ...(cartDisplayState?.context?.cart ?? { cartItems: [] }),
      isSheetOpen: cartDisplayState?.context?.isSheetOpen ?? false,
      isCartLoading: !cartDisplayState.hasTag("IsIdleState"),
      error: cartDisplayState.hasTag("Error"),
      fetchCart(): void {
        cartSend({
          type: "FetchCart",
        });
      },
      itemsAdd(cartItems: CartItemInput[]): void {
        cartSend({
          type: "AddItems",
          items: cartItems,
        });
      },
      itemsRemove(cartItemsIds: UUID[]): void {
        cartSend({
          type: "RemoveItems",
          itemIds: cartItemsIds,
        });
      },
      itemsUpdate(cartItems: CartItemUpdateInput[]): void {
        cartSend({
          type: "UpdateItems",
          items: cartItems,
        });
      },
      noteUpdate(note: string): void {
        cartSend({
          type: "UpdateNote",
          note,
        });
      },
      customerIdentityUpdate(
        customerIdentity: CartCustomerIdentityInput,
      ): void {
        cartSend({
          type: "UpdateCustomerIdentity",
          customerIdentity,
        });
      },
      cartCustomFieldsUpdate(
        cartItemId: UUID,
        customFields: CustomFieldInput[],
      ): void {
        cartSend({
          type: "UpdateCustomFields",
          cartItemId,
          customFields,
        });
      },
      couponCodeUpdate(coupon?: string): void {
        cartSend({
          type: "UpdateCoupon",
          coupon,
        });
      },
      openSheet(): void {
        cartSend({
          type: "OpenSheet",
        });
      },
      closeSheet(): void {
        cartSend({
          type: "CloseSheet",
        });
      },
      toggleSheet(): void {
        if (cartDisplayState?.context?.isSheetOpen) {
          cartSend({
            type: "CloseSheet",
          });
        } else {
          cartSend({
            type: "OpenSheet",
          });
        }
      },
      submitCart(callbackUrl: string, paymentMethod?: PaymentMethod): void {
        cartSend({
          type: "SubmitCart",
          paymentMethod,
        });
      },
      cartComplete(): void {
        cartSend({
          type: "CompleteCart",
        });
      },
    };
  }, [cartDisplayState, cartSend]);

  const [prevState, setPrevState] = useState<(typeof cartState)["value"]>(
    cartDisplayState.value,
  );
  useEffect(() => {
    const currentState = cartDisplayState.value;

    if (currentState === prevState) return;

    if (currentState === "Creating") onCreate?.();
    else if (currentState === "AddingItems") onItemAdd?.();
    else if (currentState === "RemovingItems") onItemRemove?.();
    else if (currentState === "UpdatingItems") onItemUpdate?.();
    else if (currentState === "UpdatingNote") onNoteUpdate?.();
    else if (currentState === "UpdatingCustomerIdentity")
      onCustomerIdentityUpdate?.();
    else if (currentState === "UpdatingCustomFields") onCustomFieldsUpdate?.();
    else if (currentState === "UpdatingCoupon") onCouponCodeUpdate?.();
    else if (currentState === "SubmittingCart") onSubmit?.();
    else if (currentState === "Completed") onCreateComplete?.();

    if (currentState === "Idle" && prevState === "Creating")
      onCreateComplete?.();
    else if (currentState === "Idle" && prevState === "AddingItems")
      onItemAddComplete?.();
    else if (currentState === "Idle" && prevState === "RemovingItems")
      onItemRemoveComplete?.();
    else if (currentState === "Idle" && prevState === "UpdatingItems")
      onItemUpdateComplete?.();
    else if (currentState === "Idle" && prevState === "UpdatingNote")
      onNoteUpdateComplete?.();
    else if (
      currentState === "Idle" &&
      prevState === "UpdatingCustomerIdentity"
    )
      onCustomerIdentityUpdateComplete?.();
    else if (currentState === "Idle" && prevState === "UpdatingCustomFields")
      onCustomFieldsUpdateComplete?.();
    else if (currentState === "Idle" && prevState === "UpdatingCoupon")
      onCouponCodeUpdateComplete?.();
    else if (currentState === "Idle" && prevState === "SubmittingCart")
      onSubmitComplete?.();

    setPrevState(currentState);
  }, [
    cartDisplayState.value,
    prevState,
    onCouponCodeUpdate,
    onCouponCodeUpdateComplete,
    onCreate,
    onCreateComplete,
    onCustomFieldsUpdate,
    onCustomFieldsUpdateComplete,
    onCustomerIdentityUpdate,
    onCustomerIdentityUpdateComplete,
    onItemAdd,
    onItemAddComplete,
    onItemRemove,
    onItemRemoveComplete,
    onItemUpdate,
    onItemUpdateComplete,
    onNoteUpdate,
    onNoteUpdateComplete,
    onSubmit,
    onSubmitComplete,
  ]);

  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
    </CartContext.Provider>
  );
}

export function invalidateCache(queryClient: QueryClient) {
  queryClient.invalidateQueries({ queryKey: ["cart"] });
  queryClient.invalidateQueries({
    queryKey: ["available-slots"],
  });
}

/**
 * Delays a state update until hydration finishes. Useful for preventing suspense boundaries errors when updating a context
 * @remarks this uses startTransition and waits for it to finish.
 */
function useDelayedStateUntilHydration<T>(state: T): T {
  const [isPending, startTransition] = useTransition();
  const [delayedState, setDelayedState] = useState(state);

  const firstTimePending = useRef(false);
  if (isPending) {
    firstTimePending.current = true;
  }

  const firstTimePendingFinished = useRef(false);
  if (!isPending && firstTimePending.current) {
    firstTimePendingFinished.current = true;
  }

  useEffect(() => {
    startTransition(() => {
      if (!firstTimePendingFinished.current) {
        setDelayedState(state);
      }
    });
  }, [state]);

  const displayState = firstTimePendingFinished.current ? state : delayedState;

  return displayState;
}
