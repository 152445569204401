export enum CheckoutType {
  OnePage = "onePage",
  MultiPage = "multiPage",
}

export enum CheckoutStep {
  Summary = "summary",
  SendOTP = "sendOTP",
  VerifyOTP = "verifyOTP",
  CustomerInfo = "customerInfo",
  SelectPaymentMethods = "paymentMethods",
  Payment = "payment",
  // OrderConfirmation = "orderConfirmation",
}
