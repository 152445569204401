import { create } from "zustand";

import { CustomFieldInput } from "@libs/types/customFields";

interface State {
  name: string;
  mobileNumber: string;
  otpEnabled?: boolean;
  otpToken: string;
  otpVerified: boolean;
  customFieldValues: CustomFieldInput;
}

interface Actions {
  reset: () => void;
}

const defaultState: State = {
  name: "",
  mobileNumber: "",
  otpToken: "",
  otpVerified: false,
  customFieldValues: {},
};

export const useCustomerStore = create<State & Actions>((set, get) => ({
  name: "",
  mobileNumber: "",
  otpEnabled: false,
  otpToken: "",
  customFieldValues: {},
  otpVerified: false,
  reset: () => set(defaultState),
}));
