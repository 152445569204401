import { useMemo } from "react";

import { useMachine } from "@xstate/react";

import { Toast } from "@repo/ui/components/use-toast";

import { machine } from "@website-toolkit/components/Providers/CartStateMachine";

export function useOtherMachine({
  toast,
  adminMode,
}: {
  toast: (message: Toast) => void;
  adminMode: boolean;
}) {
  const cartMachine = useMemo(() => machine(toast, adminMode), [toast]);
  return useMachine(cartMachine);
}
